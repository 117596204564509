import gql from "graphql-tag";
import { EX } from "pdfmake/build/pdfmake";

//test environmnet on services.innovativetoll.com

//getPIKEVehicles
export const GET_PIKE_VEHICLES = gql`
  query GetPIKEVehicles(
    $offset: Int!
    $limit: Int!
    $filter: PIKEVehicleFilter
    $searchParams: String
  ) {
    getPIKEVehicles(
      offset: $offset
      limit: $limit
      filter: $filter
      searchParams: $searchParams
    ) {
      count
      data {
        business_unit
        license_plate
        model
        toll_tag
        vehicleID
        vin_no
        start_date
        end_date
        unit
        client_id
        business_unit_id
        created_at
        updated_at
        vehicle_status
      }
      deactivated
      awaiting_deactivation
      active
    }
  }
`;

//LOGIN_USER_MUTATION
export const LOGIN_USER_MUTATION = gql`
  mutation LoginPIKE($username: String!, $password: String!) {
    loginPIKE(username: $username, password: $password) {
      userId
      token
      first_name
      last_name
    }
  }
`;

// getPIKEVehicle
export const GET_PIKE_VEHICLE = gql`
  query GetPIKEVehicle($vehicleId: String!) {
    getPIKEVehicle(vehicleID: $vehicleId) {
      business_unit
      license_plate
      model
      toll_tag
      vehicleID
      vin_no
      start_date
      end_date
      unit
      client_id
      business_unit_id
      created_at
      updated_at
      vehicle_status
    }
  }
`;

// getPIKEBusinessUnits
export const GET_PIKE_BUSINESS_UNITS = gql`
  query GetPIKEBusinessUnits {
    getPIKEBusinessUnits {
      business_unit_id
      business_unit
    }
  }
`;

// getPIKEBusinessUnit
export const GET_PIKE_BUSINESS_UNIT = gql`
  query GetPIKEBusinessUnit($getPikeBusinessUnitId: ID!) {
    getPIKEBusinessUnit(id: $getPikeBusinessUnitId) {
      business_unit_id
      business_unit
    }
  }
`;

// createPIKEVehicle
export const CREATE_PIKE_VEHICLE = gql`
  mutation CreatePIKEVehicle($input: PIKEVehicleInput!) {
    createPIKEVehicle(input: $input) {
      vehicleID
    }
  }
`;

// deletePIKEVehicle
export const DELETE_PIKE_VEHICLE = gql`
  mutation DeletePIKEVehicle($vehicleId: String!) {
    deletePIKEVehicle(vehicleID: $vehicleId) {
      vehicleID
    }
  }
`;
// reassignPIKEVehicle
export const REASSIGN_PIKE_VEHICLE = gql`
  mutation ReassignPIKEVehicle($businessUnitId: ID!, $vehicleId: String!) {
    reassignPIKEVehicle(
      business_unit_id: $businessUnitId
      vehicleID: $vehicleId
    ) {
      vehicleID
    }
  }
`;

// createPIKEBusinessUnits
export const CREATE_PIKE_BUSINESS_UNITS = gql`
  mutation CreatePIKEBusinessUnits($deptName: String!) {
    createPIKEBusinessUnits(dept_name: $deptName) {
      business_unit
      business_unit_id
    }
  }
`;
// updatePIKEBusinessUnits
export const UPDATE_PIKE_BUSINESS_UNITS = gql`
  mutation UpdatePIKEBusinessUnits(
    $updatePikeBusinessUnitsId: ID!
    $deptName: String!
  ) {
    updatePIKEBusinessUnits(
      id: $updatePikeBusinessUnitsId
      dept_name: $deptName
    ) {
      business_unit_id
      business_unit
    }
  }
`;
//getPIKELogs
export const GET_PIKE_LOGS = gql`
  query GetPIKELogs(
    $offset: Int!
    $limit: Int!
    $searchParams: String
    $filter: PIKEClientLogFilter
  ) {
    getPIKELogs(
      offset: $offset
      limit: $limit
      searchParams: $searchParams
      filter: $filter
    ) {
      edit_count
      add_count
      delete_count
      reassign_count
      count
      data {
        id
        vehicleID
        license_plate
        new_license_plate
        toll_tag
        new_toll_tag
        vin_no
        new_vin_no
        unit_no
        new_unit_no
        previous_business_unit
        current_business_unit
        action
        date
      }
    }
  }
`;

//update pike vehicle
export const UPDATE_PIKE_VEHICLE = gql`
  mutation UpdatePIKEVehicle($input: PIKEVehicleInput!, $vehicleId: String!) {
    updatePIKEVehicle(input: $input, vehicleID: $vehicleId) {
      vehicleID
    }
  }
`;

//getVehicleStatuses
export const GET_VEHICLE_STATUSES = gql`
  query GetVehicleStatuses {
    getVehicleStatuses {
      id
      status
    }
  }
`;

//user
export const GET_USER = gql`
  query User($userId: ID!) {
    user(userId: $userId) {
      user_id
      first_name
      last_name
      username
      email
      date_of_birth
      gender
      location
      profile_picture
      phone_number
      postalCode
      zipCode
      city
      verifyString
      verifiedEmail
      verifiedPhone
      status
      role {
        id
        group_id {
          group_id
          group_name
        }
      }
    }
  }
`;

//create business unit
export const CREATE_BUSINESS_UNIT = gql`
  mutation CreatePIKEBusinessUnits($deptName: String!) {
    createPIKEBusinessUnits(dept_name: $deptName) {
      business_unit_id
      business_unit
    }
  }
`;

//update business unit
export const UPDATE_BUSINESS_UNIT = gql`
  mutation UpdatePIKEBusinessUnits(
    $updatePikeBusinessUnitsId: ID!
    $deptName: String!
  ) {
    updatePIKEBusinessUnits(
      id: $updatePikeBusinessUnitsId
      dept_name: $deptName
    ) {
      business_unit_id
      business_unit
    }
  }
`;

//get transactions
export const GET_TRANSACTIONS = gql`
  query GetPikeTransactions(
    $offset: Int!
    $limit: Int!
    $filter: PIKETransactionFilter
    $searchParams: String
  ) {
    getPikeTransactions(
      offset: $offset
      limit: $limit
      filter: $filter
      searchParams: $searchParams
    ) {
      count
      data {
        invoice_id
        license_plate
        state_code
        agency_name
        exit_location
        exit_lane
        exit_date_time
        toll
        department {
          id
          dept_address
          dept_phone
          dept_name
          dept_email
        }
        dept_id
        dump_date
        date_for
        sub_dept_id
        dispute
        unit
        tolltag
        reason
        transponder_id
        entry_lane
        entry_location
        transaction_id
        unique_id
        higher_rate
        original_amount
        transaction_type
        axle
        account
        # updatedAt
        # createdAt
      }
    }
  }
`;
