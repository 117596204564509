<template>
  <ul
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <a
      class="sidebar-brand d-flex align-items-center justify-content-center"
      href="/transponders"
      style="background-color: white"
    >
      <img
        src="../../assets/favicon.png"
        style="height: 35px; width: 160px"
        alt="LOGO"
      />
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <!-- <li class="nav-item pl-1">
      <router-link class="nav-link" to="/dashboard"><i class="fas fa-chart-pie"></i>
        <span>DASHBOARD</span></router-link>
    </li> -->

    <li class="nav-item pl-1">
      <router-link class="nav-link" to="/transponders"
        ><i class="fas fa-car"></i> <span>TOLL UNITS</span></router-link
      >
    </li>
    <li class="nav-item pl-1">
      <router-link class="nav-link" to="/transactions"
        ><i class="fas fa-receipt"></i> <span>TRANSACTIONS</span></router-link
      >
    </li>
    <li class="nav-item pl-1">
      <router-link class="nav-link" to="/clientlogs"
        ><i class="fas fa-history"></i> <span>CLIENT LOGS</span></router-link
      >
    </li>
    <hr class="sidebar-divider" />
    <!-- addons -->
    <li class="nav-item pl-1"><span class="fw-bold">ADDONS</span></li>
    <li class="nav-item pl-1">
      <router-link class="nav-link" to="/business-units"
        ><i class="fas fa-building"></i>
        <span>BUSINESS UNITS</span></router-link
      >
    </li>
    <!-- <li class="nav-item pl-1">
      <router-link class="nav-link" to="/users"
        ><i class="fas fa-users"></i> <span>USERS</span></router-link
      >
    </li> -->
  </ul>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      role: "",
      // username: "",
    };
  },
  methods: {
    goBack() {
      window.history.back();
      window.location.reload();
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.username = this.currentUser.username;
    this.role = localStorage.getItem("role");
    this.isAdmin = this.role === "Supervisor";
  },
};
</script>
