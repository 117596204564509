<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="addNewtransponder"
    tabindex="-1"
    role="dialog"
    aria-labelledby="addNewtransponderTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addNewtransponderTitle">
            Add New Toll Transponder
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form @submit="addVehicles" role="form">
          <div class="modal-body">
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label for="license_plate" class="col-form-label"
                    >License Plate:</label
                  >
                  <Field
                    name="license_plate"
                    type="text"
                    class="form-control"
                    placeholder="license_plate"
                  />
                  <ErrorMessage
                    name="license_plate"
                    class="text-danger py-3 text-sm"
                  />
                </div>
                <div class="col-md-6">
                  <label for="model" class="col-form-label">Model:</label>
                  <Field
                    name="model"
                    type="text"
                    class="form-control"
                    placeholder="model"
                  />
                  <ErrorMessage name="model" class="text-danger py-3 text-sm" />
                </div>
                <div class="col-md-6">
                  <label for="toll_tag" class="col-form-label">Toll Tag:</label>
                  <Field
                    name="toll_tag"
                    type="text"
                    class="form-control"
                    placeholder="toll_tag"
                  />
                  <ErrorMessage
                    name="toll_tag"
                    class="text-danger py-3 text-sm"
                  />
                </div>
                <div class="col-md-6">
                  <label for="unit" class="col-form-label">Unit:</label>
                  <Field
                    name="unit"
                    type="text"
                    class="form-control"
                    placeholder="unit"
                  />
                  <ErrorMessage name="unit" class="text-danger py-3 text-sm" />
                </div>
                <div class="col-md-6">
                  <label for="vehicleID" class="col-form-label"
                    >Vehicle ID:</label
                  >
                  <Field
                    name="vehicleID"
                    type="text"
                    class="form-control"
                    placeholder="vehicleID"
                  />
                  <ErrorMessage
                    name="vehicleID"
                    class="text-danger py-3 text-sm"
                  />
                </div>
                <div class="col-md-6">
                  <label for="vin_no" class="col-form-label">Vin No:</label>
                  <Field
                    name="vin_no"
                    type="text"
                    class="form-control"
                    placeholder="vin_no"
                  />
                  <ErrorMessage
                    name="vin_no"
                    class="text-danger py-3 text-sm"
                  />
                </div>
                <div class="col-md-6">
                  <label for="business_unit_id" class="col-form-label"
                    >Business Unit:</label
                  >
                  <Field
                    name="business_unit_id"
                    class="form-control"
                    v-model="business_unit_id"
                    as="select"
                  >
                    <option value="">-- Business Unit--</option>
                    <option
                      v-for="sbsidiary in getPIKEBusinessUnits"
                      :value="sbsidiary.business_unit_id"
                      :key="sbsidiary.business_unit_id"
                    >
                      {{ headerRenderer(sbsidiary.business_unit) }}
                    </option>
                  </Field>
                  <ErrorMessage
                    name="business_unit_id"
                    class="text-danger py-3 text-sm"
                  />
                </div>
                <div class="col-md-6">
                  <label for="start_date" class="col-form-label"
                    >Start date:</label
                  >
                  <Field
                    name="start_date"
                    type="date"
                    class="form-control"
                    placeholder="start_date"
                  />
                  <ErrorMessage
                    name="start_date"
                    class="text-danger py-3 text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              Close
            </button>
            <button type="Submit" class="btn btn-success">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <div id="wrapper">
    <Header />
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <Topbar />
        <div class="panel-header bg-primary-gradient">
          <div class="page-inner px-5">
            <div
              class="d-flex align-items-left align-items-md-center flex-column flex-md-row"
            >
              <div>
                <h4 class="p-3 fw-bold">
                  <i
                    class="flaticon-delivery-truck text-primary"
                    aria-hidden="true"
                  ></i>
                  TRANSACTIONS
                </h4>
              </div>
              <!-- <div class="ml-md-auto py-2 py-md-0">
                <a
                  data-toggle="modal"
                  data-target="#addNewtransponder"
                  class="btn btn-warning btn-icon-split btn-sm"
                >
                  <span class="icon text-white-50">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="text">Upload Transactions</span>
                </a>
              </div> -->
            </div>
          </div>
        </div>
        <div class="page-inner mt--1 px-5">
          <div class="row">
            <div class="col-12">
              <div class="card shadow mt-2">
                <div class="card-header">
                  <div class="card-head-row">
                    <div class="card-tools d-flex justify-content-end">
                      <form role="form">
                        <div class="row">
                          <!-- <div class="filter-form"> -->
                          <!-- Agency Name -->
                          <div class="form-group mr-2">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text"
                                  >Agency Name:</span
                                >
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                v-model="agency_name"
                                @input="search"
                                placeholder="Enter agency name"
                              />
                            </div>
                          </div>

                          <!-- Dispute -->
                          <div class="form-group mr-2">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text">Dispute:</span>
                              </div>
                              <Field
                                name="dispute"
                                class="form-control"
                                v-model="dispute"
                                as="select"
                                @input="search"
                              >
                                <option value="">-- Dispute --</option>
                                <option value="0">Disputed</option>
                                <option value="1">Non Disputed</option>
                              </Field>
                            </div>
                          </div>

                          <!-- Start Date -->
                          <div class="form-group mr-2">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text"
                                  >Start Date:</span
                                >
                              </div>
                              <input
                                type="date"
                                class="form-control"
                                v-model="start_date"
                                @change="search"
                              />
                            </div>
                          </div>

                          <!-- End Date -->
                          <div class="form-group mr-2">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text">End Date:</span>
                              </div>
                              <input
                                type="date"
                                class="form-control"
                                v-model="end_date"
                                @change="search"
                              />
                            </div>
                          </div>

                          <!-- Year -->
                          <div class="form-group mr-2">
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text">Year:</span>
                              </div>
                              <input
                                type="number"
                                class="form-control"
                                v-model="year"
                                @input="search"
                                placeholder="Enter year"
                                min="1900"
                                max="2100"
                                step="1"
                              />
                            </div>
                          </div>

                          <!-- </div> -->
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="card-body p-3">
                  <div class="table-responsive p-0" style="width: 100%">
                    <table
                      class="table align-items-center mb-5"
                      id="transponder-table"
                      v-once
                    >
                      <thead>
                        <tr>
                          <th
                            class="text-uppercase text-xxs font-weight-bolder ps-2"
                          >
                            License Plate
                          </th>
                          <th
                            class="text-uppercase text-xxs font-weight-bolder ps-2"
                          >
                            State
                          </th>
                          <th
                            class="text-uppercase text-xxs font-weight-bolder ps-2"
                          >
                            Business Unit
                          </th>
                          <th
                            class="text-uppercase text-xxs font-weight-bolder ps-2"
                          >
                            Agency
                          </th>
                          <th
                            class="text-uppercase text-xxs font-weight-bolder ps-2"
                          >
                            Transaction Exit Date/Time
                          </th>
                          <th
                            class="text-uppercase text-xxs font-weight-bolder ps-2"
                          >
                            Exit Lane
                          </th>
                          <th
                            class="text-uppercase text-xxs font-weight-bolder ps-2"
                          >
                            Exit Location
                          </th>
                          <th
                            class="text-uppercase text-xxs font-weight-bolder ps-2"
                          >
                            Amount
                          </th>
                          <!-- <th
                            class="text-uppercase text-xxs font-weight-bolder ps-2"
                          >
                            Action
                          </th> -->
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Topbar from "@/components/common/Topbar.vue";
import Footer from "@/components/common/Footer.vue";
import Header from "@/components/common/Navbar.vue";
import "datatables.net-dt/js/dataTables.dataTables";
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.css";
import "@/assets/vendor/datatables/jquery.dataTables.min.js";
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";

import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { useQuery } from "@vue/apollo-composable";
import { computed } from "vue";
import axios from "axios";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import {
  GET_TRANSACTIONS,
  GET_PIKE_BUSINESS_UNITS,
  CREATE_PIKE_VEHICLE,
  DELETE_PIKE_VEHICLE,
  REASSIGN_PIKE_VEHICLE,
  UPDATE_PIKE_VEHICLE,
  GET_VEHICLE_STATUSES,
} from "@/graphql";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
  name: "toll-transactions",
  components: { Topbar, Footer, Header, Form, Field, ErrorMessage },
  data() {
    const { result, loading, error, refetch, fetchMore } = useQuery(
      GET_TRANSACTIONS,
      {
        offset: 0,
        limit: 10,
        filter: null,
        searchParams: "",
        // filter: { business_unit_id: 460, vehicle_status_id: 1 },
      }
    );
    const getPikeTransactionsAll = computed(
      () => result.value?.getPikeTransactions
    );

    const getPikeTransactions = computed(
      () => result.value?.getPikeTransactions?.data
    );
    return {
      getPIKEBusinessUnits: [],
      totals: 0,
      active: 0,
      deactive: 0,
      awaiting: 0,
      TollTransponders: [],
      getVehicleStatuses: [],
      showmodal: false,
      license_plate: "",
      toll_tag: "",
      model: "",
      start_date: "",
      unit: "",
      client_id: "",
      location: "",
      end_date: "",
      vin_no: "",
      amount: "",
      transactions_id: "",
      accountPostingId: "",
      referenceId: "",
      vehicleID: "",
      business_unit_id: "",
      business_unit: "",
      params: "",
      page: 1,
      vehicle_status_id: "",
      getPikeTransactions,
      getPikeTransactionsAll,
      result,
      loading,
      error,
      fetchMore,
      refetch,
      dispute: "",
      agency_name: "",
      start_date: "",
      end_date: "",
      year: new Date().getFullYear(),
    };
  },
  apollo: {
    getPIKEBusinessUnits: {
      query: GET_PIKE_BUSINESS_UNITS,
    },
    //getVehicleStatuses
    getVehicleStatuses: {
      query: GET_VEHICLE_STATUSES,
    },
  },
  methods: {
    async clientLog() {
      await this.$router.push("/client-logs");
    },
    async loaddata() {
      const self = this;
      await self.fetchMore({
        variables: {
          offset: 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {},
      });
      var columns = [
        { data: "license_plate" },
        { data: "state_code" },
        { data: "department.dept_name", className: "text-uppercase" },
        { data: "agency_name" },
        { data: "exit_date_time" },
        { data: "exit_lane" },
        { data: "exit_location" },
        { data: "toll" },
        // {
        //   data: null,
        //   render: function (data, type, row) {
        //     if (type === "display") {
        //       var actionsHtml =
        //         '<td class="d-flex justify-content-center gap-3">' +
        //         // Reassign Transponder button
        //         '<button title="Reassign Transponder" class="btn btn-link reassignTransponder" data-row=\'' +
        //         JSON.stringify(row) +
        //         "'>" +
        //         '<div class="d-flex align-items-center">' +
        //         '<i class="fas fa-share pr-2 text-warning"></i>' +
        //         "<span></span>" +
        //         "</div>" +
        //         "</button>" +
        //         "</td>";
        //       return actionsHtml;
        //     }
        //     return data;
        //   },
        // },
      ];

      const defnition = [
        // { orderable: false, targets: 7 },
        { orderable: false, targets: 7 },
      ];
      var ex_col = [1, 2, 3, 4, 5, 6, 7, 8];

      await self.fetchMore({
        variables: {
          offset: 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {},
      });

      self.totals = self?.result.getPikeTransactions.count;
      self.active = self?.result.getPikeTransactions.active;
      self.deactive = self?.result.getPikeTransactions.deactivated;
      self.awaiting = self?.result.getPikeTransactions.awaiting_deactivation;

      var vehicleData = {
        recordsTotal: self?.result?.getPikeTransactions?.count,
        recordsFiltered: self?.result?.getPikeTransactions?.count,
        data: self?.result?.getPikeTransactions?.data,
      };
      function arraysAreEqual(arr1, arr2) {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
      }

      var dataTable = $("#transponder-table").DataTable({
        destroy: true,
        lengthChange: true,
        lengthMenu: [10, 50, 100, 150, 200],
        columns: columns,
        processing: self.loading,
        pagingType: "full",
        paging: true,
        info: true,
        serverSide: true,
        fixedHeader: true,
        ajax: function (data, callback, settings) {
          callback(vehicleData);
        },
        drawCallback: function (settings) {
          $("#total").html(self.totals);
          $("#deactive").html(self.deactive);
          $("#awaiting").html(self.awaiting);
          $("#active").html(self.active);
        },

        dom: "Blfrtip",
        buttons: [
          {
            extend: "csv",
            text: '<i class="fa fa-file-excel"></i>',
            className: "btn btn-sm btn-outline-info mb-3 text-success",
            action: function newexportaction(e, dt, button, config) {
              fetchData(0, vehicleData.recordsTotal).then(() => {
                var self = this;
                var oldStart = dt.settings()[0]._iDisplayStart;
                dt.one("preXhr", function (e, s, data) {
                  data.start = 0;
                  data.length = 2147483647;

                  dt.one("preDraw", function (e, settings) {
                    if (button[0].className.indexOf("buttons-csv") >= 0) {
                      $.fn.dataTable.ext.buttons.csvHtml5.available(dt, config)
                        ? $.fn.dataTable.ext.buttons.csvHtml5.action.call(
                            self,
                            e,
                            dt,
                            button,
                            config
                          )
                        : $.fn.dataTable.ext.buttons.csvFlash.action.call(
                            self,
                            e,
                            dt,
                            button,
                            config
                          );
                    }
                    dt.one("preXhr", function (e, s, data) {
                      settings._iDisplayStart = oldStart;
                      data.start = oldStart;
                    });
                    setTimeout(dt.ajax.reload, 0);
                    return false;
                  });
                });
              });
            },
          },
          {
            extend: "pdf",
            text: '<i class="fa fa-file-pdf"></i>',
            className: "btn btn-sm btn-outline-info mb-3 text-danger",
            action: function newexportaction(e, dt, button, config) {
              // Open Loader
              fetchData(0, vehicleData.recordsTotal).then(() => {
                var self = this;
                var oldStart = dt.settings()[0]._iDisplayStart;
                dt.one("preXhr", function (e, s, data) {
                  data.start = 0;
                  data.length = 2147483647;

                  dt.one("preDraw", function (e, settings) {
                    if (button[0].className.indexOf("buttons-pdf") >= 0) {
                      $.fn.dataTable.ext.buttons.pdfHtml5.available(dt, config)
                        ? $.fn.dataTable.ext.buttons.pdfHtml5.action.call(
                            self,
                            e,
                            dt,
                            button,
                            config
                          )
                        : $.fn.dataTable.ext.buttons.pdfFlash.action.call(
                            self,
                            e,
                            dt,
                            button,
                            config
                          );
                    }
                    dt.one("preXhr", function (e, s, data) {
                      settings._iDisplayStart = oldStart;
                      data.start = oldStart;
                    });
                    setTimeout(dt.ajax.reload, 0);
                    return false;
                  });
                });
              });
            },
          },
          {
            extend: "print",
            text: '<i class="fa fa-print"></i>',
            className: "btn btn-sm btn-outline-primary mb-3 text-secondary",
            action: function newexportaction(e, dt, button, config) {
              // Open Loader
              fetchData(0, vehicleData.recordsTotal).then(() => {
                var self = this;
                var oldStart = dt.settings()[0]._iDisplayStart;
                dt.one("preXhr", function (e, s, data) {
                  data.start = 0;
                  data.length = 2147483647;
                  dt.one("preDraw", function (e, settings) {
                    if (button[0].className.indexOf("buttons-print") >= 0) {
                      $.fn.dataTable.ext.buttons.print.action(
                        e,
                        dt,
                        button,
                        config
                      );
                    }
                    dt.one("preXhr", function (e, s, data) {
                      settings._iDisplayStart = oldStart;
                      data.start = oldStart;
                    });
                    setTimeout(dt.ajax.reload, 0);
                    return false;
                  });
                });
              });
            },
          },
          {
            extend: "colvis",
            text: '<i class="fa fa-eye"></i>',
            className: "btn btn-sm btn-outline-secondary mb-3 text-primary",
          },
        ],

        language: {
          oPaginate: {
            sNext: '<i class="fa fa-forward"></i>',
            sPrevious: '<i class="fa fa-backward"></i>',
            sFirst: '<i class="fa fa-step-backward"></i>',
            sLast: '<i class="fa fa-step-forward"></i>',
          },
        },
      });

      async function fetchData(offset, length) {
        self.fetchMore({
          variables: {
            offset: offset,
            limit: length,
            // filter: ,

            searchParams: dataTable.search(),
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            let newData = fetchMoreResult?.getPikeTransactions?.data;
            let newDatafiltered = fetchMoreResult?.getPikeTransactions?.count;
            let newDataRecords = fetchMoreResult?.getPikeTransactions?.count;
            if (!arraysAreEqual(newData, self.cardArray)) {
              self.cardArray = newData;
            }

            if (
              !arraysAreEqual(
                newData,
                vehicleData.data,
                newDatafiltered,
                newDataRecords,
                vehicleData.recordsTotal,
                vehicleData.recordsFiltered
              )
            ) {
              vehicleData.data = newData;
              vehicleData.recordsTotal = newDataRecords;
              vehicleData.recordsFiltered = newDatafiltered;
              dataTable.clear().rows.add(newData).draw(false);
            }
          },
        });
      }

      dataTable.on("draw.dt", function () {
        var pageInfo = dataTable.page.info();

        var offset = pageInfo.page * pageInfo.length;
        var length = pageInfo.length;
        this.offset = offset;
        this.limit = length;

        fetchData(offset, length);
      });
      $("#transponder-table").on("change", ".select-checkbox", (event) => {
        try {
          var scanId = $(event.currentTarget).data("scan-id");
          if ($(event.currentTarget).prop("checked")) {
            this.selectedScanIds.push(scanId);
          } else {
            this.selectedScanIds = this.selectedScanIds.filter(
              (id) => id !== scanId
            );
          }
        } catch (error) {
          console.error("Error handling checkbox change:", error);
        }
      });
      $("#transponder-table").on("click", ".editTransponder", (event) => {
        var rowData = $(event.currentTarget).data("row");

        this.openEditVehicle(rowData);
      });
      $("#transponder-table").on("click", ".reassignTransponder", (event) => {
        var rowData = $(event.currentTarget).data("row");

        this.openReassignVehicle(rowData);
      });
      $("#transponder-table").on("click", ".deactivateTransponder", (event) => {
        var rowData = $(event.currentTarget).data("row");

        this.deactivateTransponder(rowData);
      });
    },
    async addVehicles(vehicle) {
      this.$apollo
        .mutate({
          mutation: CREATE_PIKE_VEHICLE,
          variables: {
            input: {
              license_plate: vehicle.license_plate,
              business_unit_id: vehicle.business_unit_id,
              start_date: vehicle.start_date,
              toll_tag: vehicle.toll_tag,
              unit: vehicle.unit,
              vehicleID: vehicle.vehicleID,
              vin_no: vehicle.vin_no,
            },
          },
        })
        .then(async (response) => {
          if (response.data.createPIKEVehicle) {
            this.$swal({
              title: `Vehicle added successfully`,
              position: "top-end",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
            $("#addNewtransponder").modal("hide");
            await this.loaddata();
          }
        })
        .catch((error) => {
          console.error("Error:", error.message);
          this.$swal({
            title: error.message,
            position: "top-end",
            icon: "warning",
            showConfirmButton: false,
            timer: 3000,
          });

          this.closeModal();
        });
    },
    closeModal() {
      console.log("closeModal called");
      const modalElement = document.getElementById("addNewtransponder");
      const modalInstance =
        bootstrap.Modal.getInstance(modalElement) ||
        new bootstrap.Modal(modalElement);

      if (modalInstance) {
        modalInstance.hide();
      } else {
        console.warn("Modal instance not found.");
      }
    },
    editVehicles(vehicle) {
      console.log(vehicle);
      this.$apollo
        .mutate({
          mutation: UPDATE_PIKE_VEHICLE,
          variables: {
            vehicleId: this.vehicleID,
            input: {
              license_plate: vehicle.license_plate,
              business_unit_id: vehicle.business_unit_id,
              start_date: vehicle.start_date,
              toll_tag: vehicle.toll_tag,
              state: vehicle.state,
              model: vehicle.model,
              unit: vehicle.unit,
              vin_no: vehicle.vin_no,
              vehicleID: this.vehicleID,
            },
          },
        })
        .then(async (response) => {
          if (response.data.updatePIKEVehicle) {
            this.$swal({
              title: "Vehicle updated successfully",
              position: "top-end",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
            const modalElement = document.getElementById("exampleModalLong");
            const modal = bootstrap.Modal.getInstance(modalElement);
            modal.hide();
            await this.loaddata();
          }
        })
        .catch((error) => {
          console.error("Error:", error.message);
          this.$swal({
            title: "Error updating vehicle",
            text: error.message,
            position: "top-end",
            icon: "warning",
            showConfirmButton: false,
            timer: 3000,
          });

          this.closeModal();
        });
    },

    openEditVehicle(vehicle) {
      this.vehicleID = vehicle.vehicleID;
      this.license_plate = vehicle.license_plate;
      this.model = vehicle.model;
      this.toll_tag = vehicle.toll_tag;
      this.unit = vehicle.unit;
      this.state = vehicle.state;
      this.vin_no = vehicle.vin_no;
      this.client_id = vehicle.client_id;
      this.start_date = vehicle.start_date;
      this.end_date = vehicle.end_date;
      this.business_unit_id = vehicle.business_unit_id;
      $("#exampleModalLong").modal("show");
    },
    deactivateTransponder(vehicleID) {
      this.$swal({
        title: "Are you sure you want to de-activate the transponder?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0D58B9",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, deactivate it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELETE_PIKE_VEHICLE,
              variables: {
                vehicleId: vehicleID.vehicleID,
              },
            })
            .then((response) => {
              if (response.data.deletePIKEVehicle) {
                this.$swal({
                  title: "Transponder deactivated successfully",
                  position: "top-end",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 3000,
                });
                this.loaddata();
              } else {
                this.$swal({
                  title: "Deactivation failed",
                  text: "The transponder could not be deactivated. Please try again later.",
                  icon: "error",
                  confirmButtonColor: "#0D58B9",
                });
              }
            })
            .catch((error) => {
              console.error("Deactivation Error:", error);
              this.$swal({
                title: "Error",
                text: error.message,
                icon: "error",
                confirmButtonColor: "#0D58B9",
              });
            });
        }
      });
    },

    async openReassignVehicle(vehicle) {
      this.vehicleID = vehicle;
      $("#reassignModal").modal("show");
    },
    async search() {
      const filters = {};

      if (this.agency_name) {
        filters.agency_name = this.agency_name;
      }
      if (Number.isInteger(Number(this.dispute))) {
        filters.dispute = Number(this.dispute);
      }

      if (this.start_date) {
        filters.start_date = this.start_date;
      }
      if (this.end_date) {
        filters.end_date = this.end_date;
      }
      if (this.year) {
        filters.year = this.year;
      }

      await this.refetch({
        offset: 0,
        limit: 10,
        filter: filters, // ✅ Now correctly passing an object
      });

      await this.fetchMore({
        variables: {
          offset: 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;

          var vehicleData = {
            recordsTotal: fetchMoreResult?.getPikeTransactions?.count,
            recordsFiltered: fetchMoreResult?.getPikeTransactions?.count,
            data: fetchMoreResult?.getPikeTransactions?.data,
          };

          $("#transponder-table")
            .DataTable()
            .clear()
            .rows.add(vehicleData.data)
            .draw();
        },
      });
    },
    headerRenderer(str) {
      let frags = str.split("_");
      for (let i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(" ");
    },
    reassignTransponders(vehicle) {
      this.$apollo
        .mutate({
          mutation: REASSIGN_PIKE_VEHICLE,
          variables: {
            vehicleId: this.vehicleID.vehicleID,
            businessUnitId: vehicle.business_unit_id,
          },
        })
        .then(async (response) => {
          if (response.data.reassignPIKEVehicle) {
            this.$swal({
              title: `Transponder reassigned successfully`,
              position: "top-end",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
            const modalElement = document.getElementById("reassignModal");
            const modal = bootstrap.Modal.getInstance(modalElement);
            modal.hide();
            await this.loaddata();
          }
        })
        .catch((error) => {
          console.error("Error:", error.message);
          this.$swal({
            title: error.message,
            position: "top-end",
            icon: "warning",
            showConfirmButton: false,
            timer: 3000,
          });

          this.closeModal();
        });
    },
  },

  async created() {
    await this.loaddata();
  },
  async mounted() {},
  computed: {
    change_page() {
      const params = this.page + 1;
      return params;
    },
  },
};
</script>
