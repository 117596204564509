<template>
  <div id="wrapper">
    <Header />
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <Topbar />
        <div class="container mt-2">
          <div
            class="d-flex justify-content-between align-items-sm-center flex-wrap flex-sm-row mb-4"
          >
            <div class="me-4 mb-3 mb-sm-0">
              <h1 class="mb-0">Dashboard</h1>
            </div>
            <div
              class="input-group input-group-joined border-0 shadow"
              style="width: 20.5rem"
            >
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-calendar"></i
                ></span>
              </div>
              <input
                type="month"
                name="filter_month"
                class="form-control"
                id="filter_month"
                v-model="filter_month"
                placeholder="yyyy-mm"
              />
              <div class="input-group-append">
                <button class="btn btn-info btn-xs" @click="onMonthChange()">
                  Filter
                </button>
              </div>
            </div>
          </div>
          <div class="card lift card-waves mb-4 mt-5">
            <div class="card-body p-5">
              <div class="row align-items-center justify-content-between">
                <div class="col">
                  <h2 class="text-primary">Welcome back, to Pike Dashboard!</h2>
                  <p class="text-gray-700">
                    This is the client end of the Pike client dashboard.
                  </p>
                  <a class="btn btn-primary p-3" href="#!">
                    Get Started
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-arrow-right ms-1"
                    >
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                  </a>
                </div>
                <div class="col d-none d-lg-block mt-xxl-n4">
                  <img
                    class="img-fluid px-xl-4 mt-xxl-n5"
                    src="@/assets/statistics.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card border-start-lg border-start-primary h-100">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <div class="small fw-bold text-primary mb-1">
                        Earnings (monthly)
                      </div>
                      <div class="h5">$4,390</div>
                      <div
                        class="text-xs fw-bold text-success d-inline-flex align-items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trending-up me-1"
                        >
                          <polyline
                            points="23 6 13.5 15.5 8.5 10.5 1 18"
                          ></polyline>
                          <polyline points="17 6 23 6 23 12"></polyline>
                        </svg>
                        12%
                      </div>
                    </div>
                    <div class="ms-2">
                      <i class="fas fa-dollar-sign fa-2x text-gray-200"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card border-start-lg border-start-secondary h-100">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <div class="small fw-bold text-secondary mb-1">
                        Average sale price
                      </div>
                      <div class="h5">$27.00</div>
                      <div
                        class="text-xs fw-bold text-danger d-inline-flex align-items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trending-down me-1"
                        >
                          <polyline
                            points="23 18 13.5 8.5 8.5 13.5 1 6"
                          ></polyline>
                          <polyline points="17 18 23 18 23 12"></polyline>
                        </svg>
                        3%
                      </div>
                    </div>
                    <div class="ms-2">
                      <i class="fas fa-tag fa-2x text-gray-200"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card border-start-lg border-start-success h-100">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <div class="small fw-bold text-success mb-1">Clicks</div>
                      <div class="h5">11,291</div>
                      <div
                        class="text-xs fw-bold text-success d-inline-flex align-items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trending-up me-1"
                        >
                          <polyline
                            points="23 6 13.5 15.5 8.5 10.5 1 18"
                          ></polyline>
                          <polyline points="17 6 23 6 23 12"></polyline>
                        </svg>
                        12%
                      </div>
                    </div>
                    <div class="ms-2">
                      <i class="fas fa-mouse-pointer fa-2x text-gray-200"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
              <div class="card border-start-lg border-start-info h-100">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <div class="small fw-bold text-info mb-1">
                        Conversion rate
                      </div>
                      <div class="h5">1.23%</div>
                      <div
                        class="text-xs fw-bold text-danger d-inline-flex align-items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trending-down me-1"
                        >
                          <polyline
                            points="23 18 13.5 8.5 8.5 13.5 1 6"
                          ></polyline>
                          <polyline points="17 18 23 18 23 12"></polyline>
                        </svg>
                        1%
                      </div>
                    </div>
                    <div class="ms-2">
                      <i class="fas fa-percentage fa-2x text-gray-200"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mb-4">
              <div class="card mb-4">
                <div class="card-body text-center p-5">
                  <img
                    class="img-fluid mb-5"
                    src="https://sb-admin-pro.startbootstrap.com/assets/img/illustrations/data-report.svg"
                  />
                  <h4>Report generation</h4>
                  <p class="mb-4">
                    Ready to get started? Let us know now! It's time to start
                    building that dashboard you've been waiting to create!
                  </p>
                  <a class="btn btn-primary p-3" href="#!">Continue</a>
                </div>
              </div>
            </div>
            <div class="col-lg-8 mb-4">
              <div class="card mb-4">
                <div class="card-header">Revenue Summary</div>
                <div class="card-body">
                  <div class="chart-area">
                    <div class="chartjs-size-monitor">
                      <div class="chartjs-size-monitor-expand">
                        <div class=""></div>
                      </div>
                      <div class="chartjs-size-monitor-shrink">
                        <div class=""></div>
                      </div>
                    </div>
                    <canvas
                      id="myAreaChart"
                      width="875"
                      height="240"
                      style="display: block; width: 875px; height: 240px"
                      class="chartjs-render-monitor"
                    ></canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import TokenService from "@/services/token.service.js";

import Topbar from "@/components/common/Topbar.vue";
import Footer from "@/components/common/Footer.vue";
import Header from "@/components/common/Navbar.vue";
import "datatables.net-dt/js/dataTables.dataTables";
import "@/assets/css/sb-admin-2.css";
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.css";
import "@/assets/vendor/datatables/jquery.dataTables.min.js";
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";

import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios from "axios";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "toll-logs",
  components: { Topbar, Footer, Header, Form, Field, ErrorMessage },
  data() {
    return {
      filter_month: new Date().toISOString().slice(0, 7),
    };
  },

  computed: {},
  methods: {
    onMonthChange() {
      this.filter_month = document.getElementById("filter_month").value;
      console.log("Selected month:", this.filter_month);
    },
  },

  async mounted() {},
};
</script>
<style scoped></style>
