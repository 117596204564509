<template>
  <div class="page-header min-vh-100">
    <div class="container" v-if="passwordmodal">
      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-9 h-100">
          <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-lg-6 d-none d-lg-block bg-password-image"></div>
                <div class="col-lg-6">
                  <div class="p-5">
                    <div class="text-center">
                      <h1 class="h4 text-gray-900 mb-2">Reset Password!</h1>
                    </div>
                    <Form
                      @submit="updatePassword"
                      :validation-schema="schema"
                      role="form"
                    >
                      <div class="form-group">
                        <div class="mb-3">
                          <label for="code"
                            >Please enter the verification code sent to your
                            email</label
                          >
                          <Field
                            name="code"
                            type="text"
                            class="form-control form-control-lg"
                            placeholder="verification code"
                            v-model="code"
                          />
                          <ErrorMessage
                            name="code"
                            class="text-danger py-3 text-sm"
                          />
                        </div>
                        <div class="mb-3">
                          <label for="code">Enter password</label>
                          <Field
                            name="password"
                            type="password"
                            ref="password"
                            class="form-control form-control-lg"
                            placeholder="Password"
                            v-model="password"
                          />
                          <ErrorMessage
                            name="password"
                            class="text-danger py-3 text-sm"
                          />
                        </div>
                        <div class="mb-3">
                          <label for="code">Confirm password</label>
                          <Field
                            name="password_confirmation"
                            type="password"
                            class="form-control form-control-lg"
                            placeholder="password_confirmation"
                            v-model="confirmPassword"
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            class="text-danger py-3 text-sm"
                          />
                        </div>
                      </div>
                      <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </Form>
                    <hr />
                    <div class="text-center">
                      <router-link class="small" to="/login"
                        >Already have an account? Login!</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="showmodel">
      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-9 h-100">
          <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
              <div class="row">
                <div class="col-lg-6 d-none d-lg-block bg-password-image"></div>
                <div class="col-lg-6">
                  <div class="p-5">
                    <div class="text-center">
                      <h1 class="h4 text-gray-900 mb-2">
                        Forgot Your Password?
                      </h1>
                      <p class="mb-4">
                        We get it, stuff happens. Just enter your email address
                        below and we will send you the OTP code to reset your
                        password!
                      </p>
                    </div>
                    <Form
                      @submit="sendCode"
                      :validation-schema="email_v"
                      role="form"
                    >
                      <div class="form-group">
                        <Field
                          name="email"
                          type="email"
                          class="form-control form-control-user"
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                          placeholder="Enter email address.."
                          v-model="email"
                        />
                        <ErrorMessage
                          name="email"
                          class="text-danger py-3 text-sm"
                        />
                      </div>
                      <button
                        type="submit"
                        class="btn btn-primary btn-user btn-block"
                      >
                        Send Code
                      </button>
                    </Form>
                    <hr />
                    <div class="text-center">
                      <router-link class="small" to="/login"
                        >Already have an account? Login!</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import {
  REQUEST_RESETPASSWORD_MUTATION,
  RESETPASSWORD_MUTATION,
} from "@/graphql";
export default {
  name: "forget-password",
  components: { Form, Field, ErrorMessage },

  data() {
    const schema = yup.object().shape({
      password: yup
        .string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords must match"),
    });
    const email_v = yup.object().shape({
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
    });
    return {
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
      userId: "",
      showmodel: true,
      passwordmodal: false,
      schema,
      email_v,
    };
  },

  methods: {
    async sendCode() {
      await this.$apollo
        .mutate({
          mutation: REQUEST_RESETPASSWORD_MUTATION,
          variables: {
            email: this.email,
          },
        })
        .then((response) => {
          if (response.data.requestResetPassword) {
            this.userId = response.data.requestResetPassword.userId;
            this.closemodal();
            this.$swal({
              title: "Email sent sucessfully",
              position: "top-end",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
            });
          }
          this.passwordmodal = true;
        })
        .catch((error) => {
          this.closemodal();
          this.$swal({
            title: error.message,
            position: "top-end",
            icon: "warning",
            showConfirmButton: false,
            timer: 3000,
          });
          setTimeout(() => {
            this.$router.go(0);
          }, 1000);
        });
    },
    async updatePassword() {
      await this.$apollo
        .mutate({
          mutation: RESETPASSWORD_MUTATION,
          variables: {
            code: this.code,
            userId: this.userId,
            password: this.password,
            confirmPassword: this.confirmPassword,
          },
        })
        .then((response) => {
          if (response.data.resetPassword) {
            this.$swal({
              title: "Password has been reset successfully",
              position: "top-end",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
            });
          }
          this.login();
        })
        .catch((error) => {
          this.$swal({
            title: error.message,
            position: "top-end",
            icon: "warning",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    closemodal() {
      this.showmodel = false;
      this.passwordmodal = false;
    },
    forget() {
      this.$router.go(0);
    },
    login() {
      this.$router.push("/login");
    },
  },
};
</script>
