<template>
  <html lang="en" class="fontawesome-i2svg-active fontawesome-i2svg-complete">
    <body
      class="nav-fixed"
      data-new-gr-c-s-check-loaded="14.1139.0"
      data-gr-ext-installed=""
    >
      <nav
        class="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white"
        id="sidenavAccordion"
      >
        <ul class="navbar-nav align-items-center ms-auto">
          <li class="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
            <a
              class="btn btn-icon btn-transparent-dark dropdown-toggle"
              id="navbarDropdownUserImage"
              href="javascript:void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              ><img
                class="img-fluid"
                src="assets/img/illustrations/profiles/profile-1.png"
            /></a>
            <div
              class="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up"
              aria-labelledby="navbarDropdownUserImage"
            >
              <h6 class="dropdown-header d-flex align-items-center">
                <img
                  class="dropdown-user-img"
                  src="assets/img/illustrations/profiles/profile-1.png"
                />
                <div class="dropdown-user-details">
                  <div class="dropdown-user-details-name">Valerie Luna</div>
                  <div class="dropdown-user-details-email">vluna@aol.com</div>
                </div>
              </h6>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#!">
                <div class="dropdown-item-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-settings"
                  >
                    <circle cx="12" cy="12" r="3"></circle>
                    <path
                      d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                    ></path>
                  </svg>
                </div>
                Account
              </a>
              <a class="dropdown-item" href="#!">
                <div class="dropdown-item-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-log-out"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                </div>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <div id="layoutSidenav">
        <div id="layoutSidenav_content">
          <main>
            <header
              class="page-header page-header-compact page-header-light border-bottom bg-white mb-4"
            >
              <div class="container-xl px-4">
                <div class="page-header-content">
                  <div
                    class="row align-items-center justify-content-between pt-3"
                  >
                    <div class="col-auto mb-3">
                      <h1 class="page-header-title">
                        <div class="page-header-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-user"
                          >
                            <path
                              d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
                            ></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                        </div>
                        Account Settings - Profile
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div class="container-xl px-4 mt-4">
              <nav class="nav nav-borders">
                <a class="nav-link active ms-0" href="account-profile.html"
                  >Profile</a
                >
                <a class="nav-link" href="account-billing.html">Billing</a>
                <a class="nav-link" href="account-security.html">Security</a>
                <a class="nav-link" href="account-notifications.html"
                  >Notifications</a
                >
              </nav>
              <hr class="mt-0 mb-4" />
              <div class="row">
                <div class="col-xl-4">
                  <div class="card mb-4 mb-xl-0">
                    <div class="card-header">Profile Picture</div>
                    <div class="card-body text-center">
                      <img
                        class="img-account-profile rounded-circle mb-2"
                        src="assets/img/illustrations/profiles/profile-1.png"
                        alt=""
                      />
                      <div class="small font-italic text-muted mb-4">
                        JPG or PNG no larger than 5 MB
                      </div>
                      <button class="btn btn-primary" type="button">
                        Upload new image
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xl-8">
                  <div class="card mb-4">
                    <div class="card-header">Account Details</div>
                    <div class="card-body">
                      <form>
                        <div class="mb-3">
                          <label class="small mb-1" for="inputUsername"
                            >Username (how your name will appear to other users
                            on the site)</label
                          >
                          <input
                            class="form-control"
                            id="inputUsername"
                            type="text"
                            placeholder="Enter your username"
                            value="username"
                          />
                        </div>
                        <div class="row gx-3 mb-3">
                          <div class="col-md-6">
                            <label class="small mb-1" for="inputFirstName"
                              >First name</label
                            >
                            <input
                              class="form-control"
                              id="inputFirstName"
                              type="text"
                              placeholder="Enter your first name"
                              value="Valerie"
                            />
                          </div>
                          <div class="col-md-6">
                            <label class="small mb-1" for="inputLastName"
                              >Last name</label
                            >
                            <input
                              class="form-control"
                              id="inputLastName"
                              type="text"
                              placeholder="Enter your last name"
                              value="Luna"
                            />
                          </div>
                        </div>
                        <div class="row gx-3 mb-3">
                          <div class="col-md-6">
                            <label class="small mb-1" for="inputOrgName"
                              >Organization name</label
                            >
                            <input
                              class="form-control"
                              id="inputOrgName"
                              type="text"
                              placeholder="Enter your organization name"
                              value="Start Bootstrap"
                            />
                          </div>
                          <div class="col-md-6">
                            <label class="small mb-1" for="inputLocation"
                              >Location</label
                            >
                            <input
                              class="form-control"
                              id="inputLocation"
                              type="text"
                              placeholder="Enter your location"
                              value="San Francisco, CA"
                            />
                          </div>
                        </div>
                        <div class="mb-3">
                          <label class="small mb-1" for="inputEmailAddress"
                            >Email address</label
                          >
                          <input
                            class="form-control"
                            id="inputEmailAddress"
                            type="email"
                            placeholder="Enter your email address"
                            value="name@example.com"
                          />
                        </div>
                        <div class="row gx-3 mb-3">
                          <div class="col-md-6">
                            <label class="small mb-1" for="inputPhone"
                              >Phone number</label
                            >
                            <input
                              class="form-control"
                              id="inputPhone"
                              type="tel"
                              placeholder="Enter your phone number"
                              value="555-123-4567"
                            />
                          </div>
                          <div class="col-md-6">
                            <label class="small mb-1" for="inputBirthday"
                              >Birthday</label
                            >
                            <input
                              class="form-control"
                              id="inputBirthday"
                              type="text"
                              name="birthday"
                              placeholder="Enter your birthday"
                              value="06/10/1988"
                            />
                          </div>
                        </div>
                        <button class="btn btn-primary" type="button">
                          Save changes
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </body>
  </html>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import Cookies from "js-cookie";
import { LOGIN_USER_MUTATION } from "@/graphql";

import * as yup from "yup";
export default {
  name: "Login",
  components: { Form, Field, ErrorMessage },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string("Email is required!")
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup
        .string("Password is required!")
        .required("Password is required!"),
    });
    return {
      loading: false,
      message: "",
      schema,
    };
  },
  methods: {
    async handleLogin(user) {
      this.loading = true;
      this.message = "";

      try {
        const { data } = await this.$apollo.mutate({
          mutation: LOGIN_USER_MUTATION,
          variables: {
            username: user.email,
            password: user.password,
          },
        });
        const token = data.login.token;
        Cookies.set("authToken", token);
        this.$router.push("/transponders");
      } catch (error) {
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.bg_image {
  background-image: url(https://images.pexels.com/photos/357514/pexels-photo-357514.jpeg?auto=compress&cs=tinysrgb&w=1600g);
  background-size: cover;
  background-position: center;
}
</style>
