import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";

import Register from "../views/Register.vue";

import ResetPassword from "../views/resetPassword.vue";
import Cookies from "js-cookie";
import Transactions from "@/views/Transactions.vue";

// Listen for the 'beforeunload' event
window.addEventListener("beforeunload", (event) => {
  // Check if the tab is being closed
  if (event.clientX < 0 && event.clientY < 0) {
    // Clear token and user information when the tab is closed
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
});
const authGuard = (to, from, next) => {
  const isAuthenticated = localStorage.getItem("token") !== null;
  if (
    isAuthenticated &&
    (to.path === "/login" || to.path === "/" || to.path === "/forget")
  ) {
    next("/transponders");
  } else if (isAuthenticated || to.path === "/login" || to.path === "/forget") {
    next();
  } else {
    next("/login");
  }
};

const routes = [
  { path: "/", name: "login", component: Login },
  { path: "/register", name: "Register", component: Register },
  { path: "/login", component: Login },

  {
    path: "/forget",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/transponders",
    name: "Transponders",
    component: () => import("../views/Transponders.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: authGuard,
    meta: { title: "Dashboard" },
  },

  {
    path: "/profile",
    name: "My Profile",
    component: () => import("../views/Profile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Profile" },
  },
  //client logs
  {
    path: "/clientlogs",
    name: "Client Logs",
    component: () => import("../views/ClientLogs.vue"),
    beforeEnter: authGuard,
    meta: { title: "Client Logs" },
  },
  //BUSINESS-UNITS
  {
    path: "/business-units",
    name: "Business Units",
    component: () => import("../views/BusinessUnits.vue"),
    beforeEnter: authGuard,
    meta: { title: "Business Units" },
  },

  //USERS
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Users.vue"),
    beforeEnter: authGuard,
    meta: { title: "Users" },
  },

  // Transactions
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
    beforeEnter: authGuard,
    meta: { title: "Transactions" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(authGuard);

export default router;
