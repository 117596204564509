<template>
  <nav
    class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow"
  >
    <!-- Sidebar Toggle (Topbar) -->
    <button
      id="sidebarToggleTop"
      class="btn btn-link d-md-none rounded-circle mr-3"
    >
      <i class="fa fa-bars"></i>
    </button>
    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
      <!-- Nav Item - Search Dropdown (Visible Only XS) -->

      <div class="topbar-divider d-none d-sm-block"></div>
      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="userDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="mr-2 d-none d-lg-inline text-gray-600 small"
            >{{ this.first_name }} {{ this.last_name }}
          </span>
          <img
            class="img-profile rounded-circle"
            src="img/undraw_profile.svg"
          />
        </a>
        <!-- Dropdown - User Information -->
        <div
          class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
          aria-labelledby="userDropdown"
        >
          <!-- <router-link class="dropdown-item" to="/profile"><i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
            Profile</router-link>
          <div class="dropdown-divider"></div> -->
          <a
            class="dropdown-item"
            href="#"
            data-toggle="modal"
            data-target="#logoutModal"
          >
            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            Logout
          </a>
        </div>
      </li>
    </ul>
  </nav>
  <!-- Logout Modal-->
  <div
    class="modal fade"
    id="logoutModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          Select "Logout" below if you are ready to end your current session.
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-dismiss="modal">
            Cancel
          </button>
          <a class="btn btn-primary" href="/login" @click="logout">Logout</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { CURRENT_USER_MUTATION, LOGOUT_MUTATION } from "@/graphql";
import TokenService from "@/services/token.service.js";
import { parse } from "graphql";

export default {
  name: "Topbar",
  data() {
    return {
      loading: false,
      message: "",
      currentUser: [],
      user_id: "",
      first_name: "",
      last_name: "",
    };
  },

  methods: {
    logout() {
      // this.$apollo
      //   .query({
      //     query: LOGOUT_MUTATION,
      //   })
      //   .then((response) => {
      //     localStorage.clear();
      //   })
      //   .catch((error) => {
      //     console.log(error.message);
      //   });
      localStorage.clear();
      // this.$router.push("/login");

      Cookies.remove("token");
      TokenService.removeUser();
    },
  },
  async mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.first_name = this.currentUser.first_name;
    this.last_name = this.currentUser.last_name;
    this.user_id = this.currentUser.userId;
    this.role = localStorage.getItem("role");
  },
};
</script>
